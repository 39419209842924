<template>
  <div class="profile-modal">
    <div class="profile-tab-blk" v-if="driverId !== 0">
      <div class="details-tab-modal">
        <v-card elevation="2" class="profile-blk">
          <DriverDetails @trashChanged="handleDriverdashboard" :driverDetails="driverDetails" @edit-driver="editDriver"></DriverDetails>
        </v-card>
      </div>
      <div class="profile-tab-modal">
        <v-card class="vehicle-assign-modal" elevation="2">
          <span class="vehicle-assign-header">Vehicle Assign</span>
          <v-autocomplete v-model="vehicle" placeholder="Vehicle" label="Vehicle" outlined dense :items="vehicleList" return-object item-text="vehicleNo" hide-details></v-autocomplete>
          <div class="assign-button-blk">
            <v-btn color="#eea004" :disabled="vehicle === ''" @click="assignVehicle">Assign</v-btn>
          </div>
        </v-card>
      </div>
    </div>
    <div v-else class="no-driver-profile">No data Available</div>
    <Loading v-if="loading"></Loading>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import DriverDetails from "@/components/driver-management/driver-details/DriverDetails";
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";

export default {
  name: "DriverProfile",
  components: { Confirm, Loading, DriverDetails },
  props: {
    driverId: Number,
    driverName: String,
    driverToken: String,
  },
  data: () => {
    return {
      vehicle: "",
      vehicleList: [],
      driverDetails: {},
      loading: false,
    };
  },
  watch: {
    vehicle() {
      if (this.vehicle === null) {
        this.vehicle = "";
      }
    },
    driverId() {
      this.getDriverDetails();
      this.vehicle = "";
    },
  },
  mounted() {                                                                   
    this.getVehicleList();
    this.vehicle = "";
  },
  methods: {


    async getVehicleList() {
      const response = await axios.post("vehicle/list");
      this.vehicleList = response.data;
    },
    async handleDriverdashboard()
{
  this.$emit('finalemit')
},
    async getDriverDetails() {
      if (this.driverId !== 0) {
        this.loading = true; 
        const response = await axios.post("driver/details", { driverId: this.driverToken });
        this.loading = false;
        this.driverDetails = response.data;
      }
    },
    async assignVehicle() {
      const payload = {
        vehicleNo: this.vehicle.vehicleNo,
        driver: {
          token: this.driverToken,
          name: this.driverName,
        },
      };
      let confirmTitle = "Confirm Assign";
      let confirmText = `Please confirm to Assign ${this.vehicle.vehicleNo} to ${this.driverName}`;
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true;
        try {
          const response = await axios.patch("driver/vehicle/link", payload);
          if (response.status === 200) {
            this.driverDetails.vehicles.push({ vehicleno: this.vehicle.vehicleNo, id: this.vehicle.token });
            this.vehicle = "";
            this.loading = false;
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
          } else if (response.status === 206) {
            this.loading = false;
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
          }
        } catch (error) {
          this.loading = false;
          // this.$toasted.show(error.response.data, {
          //   position: "bottom-center",
          //   duration: 4000
          // });
        }
      }
    },
    editDriver(data) {
      this.driverDetails = data;
      this.$emit("emit-driver-details", data);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./driverProfile";
</style>
