<template>
  <div class="main-inner-blk">
    <v-card class="vehicle-filter-blk" elevation="3">
      <Filters :placeHolder="'Search Vehicle Number'" @get-filter="getFilters"></Filters>
    </v-card>

    <div class="vehicle-modal">
      <div class="vehicle-list-modal">
        <VehicleList 
          @load-more-vehicles="loadMoreVehicles" 
          :page="page" 
          :items="items" 
          :vehicleList="vehicleList" 
          @insert-vehicle="addVehicle" 
          @select-vehicle="selectVehicle" 
          :id="vehicleId" 
          @insert-cab="insertVehicle">
        </VehicleList>
      </div>
      <div class="vehicle-content-modal">
        <v-tabs background-color="black" color="#facc15" slider-color="#facc15" dark v-model="tabs">
          <v-tab href="#cab-profile-tab" :disabled="tabs === 'cab-insert-tab'">Profile</v-tab>
          <v-tab href="#cab-insert-tab" v-if="tabs === 'cab-insert-tab'">{{ textName }} Vehicle</v-tab>
          <v-tab href="#cab-document-tab" :disabled="tabs === 'cab-insert-tab'">Documents</v-tab>
          <v-tab href="#cab-remarks-list" :disabled="tabs === 'cab-insert-tab'">List</v-tab>

          <v-tabs-items v-model="tabs" class="vehicle-tab-items">
            <v-tab-item id="cab-insert-tab">
              <VehicleInsert
                @insert-cab="insertVehicle"
                @cancel-insert="cancelInsert"
                :tabName="tabs"
                :vehicleDetails="vehicleDetails"
                :editStatus="editStatus"
                @vehicle-edit="vehicleEdit">
              </VehicleInsert>
            </v-tab-item>

            <v-tab-item id="cab-profile-tab">
              <VehicleProfile 
                :vehicleToken="vehicleToken" 
                :vehicleId="vehicleId" 
                @edit-vehicle-data="editVehicle">
              </VehicleProfile>
            </v-tab-item>

            <v-tab-item id="cab-document-tab">
              <VehicleDocument 
                :vehicleId="vehicleId" 
                :vehicleToken="vehicleToken">
              </VehicleDocument>
            </v-tab-item>

            <v-tab-item id="cab-remarks-list">
              <VehicleRemarksList></VehicleRemarksList>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>
    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import Filters from "@/components/common/filter/Filters";
import VehicleList from "@/components/vehicle-management/vehicle-list/VehicleList";
import VehicleInsert from "@/components/vehicle-management/vehicle-insert/VehicleInsert";
import VehicleDocument from "@/components/vehicle-management/vehicle-document/VehicleDocument";
import axios from "@/axios/axios-global";
import VehicleProfile from "@/components/vehicle-management/vehicle-profile/VehicleProfile";
import Loading from "@/components/common/Loading";
import VehicleRemarksList from "@/components/vehicle-management/vehicle-remarks-list/VehicleRemarksList";

export default {
  name: "VehicleDashboard",
  components: { Loading, VehicleProfile, VehicleInsert, VehicleList, Filters, VehicleDocument, VehicleRemarksList },
  data: () => {
    return {
      vehicleList: [],
      tabs: "cab-profile-tab",
      textName: "Add",
      vehicleId: 0,
      vehicleToken: "",
      editStatus: false,
      vehicleDetails: {},
      loading: false,
      filters: "",
      page: 1,
      items: 20
    };
  },
  mounted() {
    this.getVehicleList(this.filters);
  },
  methods: {
    getFilters(data) {
      this.filters = data;
      this.getVehicleList(this.filters);
    },
    async getVehicleList(data) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.loading = true;
        const response = await axios.post("vehicle/list", { search: data, page: this.page, items: this.items });
        console.log(response);
        this.loading = false;
        this.vehicleList=this.page===1?response.data:[...this.vehicleList,...response.data]
        if (this.vehicleList.length > 0) {
          this.vehicleId = this.vehicleList[0].id;
          this.vehicleToken = this.vehicleList[0].token;
        } else {
          this.vehicleId = 0;
        }
      }, 300);
    },
    insertVehicle(data) {
      this.vehicleList.unshift(data);
      this.tabs = "cab-profile-tab";
      this.vehicleId = data.id;
      this.vehicleToken = data.token;
    },
    addVehicle() {
      this.tabs = "cab-insert-tab";
      this.editStatus = false;
      if (this.textName === "Edit") {
        this.tabs = "cab-profile-tab";
        setTimeout(() => {
          this.tabs = "cab-insert-tab";
        }, 0);
      }
      this.textName = "Add";
    },
    cancelInsert() {
      this.tabs = "cab-profile-tab";
    },
    selectVehicle(data) {
      this.tabs = "cab-profile-tab";
      this.vehicleId = data.id;
      this.vehicleToken = data.token;
    },
    editVehicle(data) {
      this.tabs = "cab-insert-tab";
      this.vehicleDetails = data;
      this.editStatus = true;
      this.textName = "Edit";
    },
    vehicleEdit(data) {
      const index = this.vehicleList.findIndex((item) => item.id === data.id);
      if (index !== -1) {
        this.vehicleList[index].vehicleNo = data.vehicleNo;
        this.vehicleList[index].model = data.model;
        this.vehicleList[index].manufacturer = data.manufacturer;
      }
      this.tabs = "cab-profile-tab";
      this.vehicleId = 0;
      setTimeout(() => {
        this.vehicleId = data.id;
      }, 200);
      this.$emit("edited-vehicle", data);
    },
    loadMoreVehicles() {
      console.log("Loading more vehicles...");
      this.page++;
      this.getVehicleList(this.filters);
    }
  }
};
</script>

<style scoped lang="scss">
@import "./vehicleDashboard";
</style>
