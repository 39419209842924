<template>
  <div class="driver-list">
    <v-data-table :headers="headers" :items="driverData" class="elevation-1" :items-per-page="5">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Drivers Remarks List</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="item.status === true ? 'green' : 'red'" small dark>{{ item.status === true ? "Active" : "Inactive" }}</v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "DriversRemarksList",
  data: () => {
    return {
      headers: [
        { text: "Driver Name", align: "start", sortable: true, value: "name" },
        { text: "Reason", value: "reason" },
        { text: "Date", value: "date" },
        { text: "Status", value: "status", sortable: true },
      ],
      driverData: [
        { name: "John Doe", reason: "Violation of company policy", date: "2022-01-01", status: true },
        { name: "Jane Smith", reason: "Repeated tardiness", date: "2022-01-02", status: false },
        { name: "Mark Johnson", reason: "Unsafe driving behavior", date: "2022-01-03", status: true },
        { name: "Emily Davis", reason: "Lack of communication", date: "2022-01-04", status: false },
        { name: "Michael Brown", reason: "Insubordination", date: "2022-01-05", status: true },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.driver-list {
  padding: 10px;
}
</style>
