<template>
  <div class="driver-details-modal" v-if="driverDetails">
    <div class="driver-image-blk text-center" v-if="driverDetails.document">
      <img :src="driverDetails.image" class="image-blk" />
    </div>
    <div class="name-blk text-center">
      <span>{{ driverDetails.name }}</span>
    </div>
    <div class="text-center">
      <span><i class="fas fa-phone-alt"></i>{{ driverDetails.mobile }}</span>
    </div>
    <div class="text-center color-gray-blk" v-if="driverDetails.document">
      <span v-if="driverDetails.document.licensefront.no"><b>Licence No:</b>{{ driverDetails.document.licensefront.no }}</span>
      <span v-else><b>Licence No:</b>No Data Added</span>
    </div>
    <div class="text-center color-gray-blk" v-if="driverDetails.document">
      <span><b>Licence Expiry Date:</b>{{ getExpDate(driverDetails.document.licensefront.expdate) }}</span>
    </div>
    <div v-if="driverDetails.vehicles">
      <div class="text-center vehicle-list-modal" v-if="driverDetails.vehicles.length > 0">
        <div class="vehicle-list-blk" v-for="(data, index) in driverDetails.vehicles" :key="index">
          <span>{{ data.vehicleno }}</span>
          <span class="margin-blk" @click="unlinkVehicle(data)"><i class="fas fa-minus-circle"></i></span>
        </div>
      </div>
    </div>
    <div class="text-center no-vehicle-modal" v-if="driverDetails.vehicles.length === 0">
      <span class="vehicle-text">No Vehicle Assigned</span>
    </div>


    <div class="edit-button-modal">
      <v-btn v-if="driverDetails.trash !== 1" color="#e9bd00" @click="showDeactivateModal">Deactivate Driver</v-btn>
      <v-btn v-else color="grey" @click="activateDriver"  >Deactivated </v-btn>
<!-- <v-text>Reason - {{ driverDetails.reason[0].message}}</v-text>   -->
      <v-btn color="#e9bd00" @click="editDriverDetails">Edit</v-btn>
    </div>
    <div v-if="driverDetails.trash === 1" class="deactivation-reason">
      <p><b>Reason - </b> {{ driverDetails.reason.length> 0 ? driverDetails.reason[driverDetails.reason.length-1].message : 'No reason provided' }}</p>
    </div>

    <Loading v-if="loading"></Loading>
    <Confirm ref="confirm"></Confirm> 
    <DeactivationModal v-if="deactivateModal" title="Deactivate Driver" :show="deactivateModal" :deactivateReason.sync="deactivateReason" :onConfirm="deactivateDriver" @close-modal="closeModal">
    </DeactivationModal>
  </div> 
</template>

<script>
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import * as moment from "moment";
import Confirm from "@/components/common/Confirm";
import DeactivationModal from "@/components/modal/DeactivationModal";

export default {
  name: "DriverDetails",
  components: { Confirm, Loading, DeactivationModal },
  props: {
    driverDetails: Object,
  },
  data: () => {
    return {
      baseUrl: "https://taski2020.s3.ap-south-1.amazonaws.com/",

      loading: false,
      deactivateModal: false,
      deactivateReason: "",
      trash: null,
    };
  },
  mounted() {
    console.log(this.driverDetails);
  },
  watch: {
    driverDetails() {
      console.log(this.driverDetails, "__________________________-");
    },
    trash(newValue) {
    if (newValue === 1) {
      this.$emit('trashChanged'); 
        console.log('Event emitted: trashChanged');

    }
  }
  },
  methods: {
    closeModal() {
      this.deactivateModal = false;
      this.deactivateReason = "";
    },
    async unlinkVehicle(vehicle) {
      let confirmTitle = "Confirm UnAssign";
      let confirmText = `Please Confirm to Unassign ${vehicle.vehicleno} from ${this.driverDetails.name}`;
      let confirmIcon = "fas fa-exclamation";
      if (
        await this.$refs.confirm.open(confirmTitle, confirmText, {
          icon: confirmIcon,
          color: "red",
        })
      ) {
        this.loading = true;
        try {
          const response = await axios.patch("driver/vehicle/unLink", {
            vehicleToken: vehicle.id,
            driverToken: this.driverDetails.token,
          });
          console.log(response);
          if (response.status === 200) {
            this.loading = false;
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
            const index = this.driverDetails.vehicles.findIndex((item) => item.id === vehicle.id);
            this.driverDetails.vehicles.splice(index, 1);
          }
        } catch (error) {
          this.loading = false;
          // this.$toasted.show(error.response.data, {
          //   position: "bottom-center",
          //   duration: 4000
          // });
        }
      }
    },
    getExpDate(data) {
      if (data === "") {
        return "No Data Added";
      } else {
        return moment(data).format("DD-MM-YYYY");
      }
    },
    editDriverDetails() {
      this.$emit("edit-driver", this.driverDetails);
    },

    showDeactivateModal() {
      this.deactivateModal = true;
    },
    async deactivateDriver(reason) {  
      if (!reason) {
        this.$toasted.show("Please provide a reason for deactivation.", {
          position: "bottom-center",
          duration: 4000,
        });
        return;
      }
      const index=this.driverDetails.reason.length+1  || 1
      console.log("🚀 ~ deactivateDriver ~ index:", index)

      const payload = {
        driverId: this.driverDetails.token,
        id: index,
        date: moment.now(),
        reason: reason,
        remarksBy: {
          name: localStorage.getItem("vendorName"),
        },
      };

      this.loading = true;
      try {
        const response = await axios.post("driver/deactivateDriver", payload);
        if (response.status === 200) {
          this.$emit('trashChanged'); 
          this.driverDetails.trash=1
          this.loading = false;
          this.deactivateModal = false;
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000,
          });
      
        }
      } catch (error) {
        this.loading = false;
        this.$toasted.show("An error occurred. Please try again.", {
          position: "bottom-center",
          duration: 4000,
        });
      }
    },
    async activateDriver() {
      const index=this.driverDetails.reason.length+1  || 1
      console.log("🚀 ~ activateDriver ~ index:", index)
      let confirmTitle = "Driver Activate";
      let confirmText = `Please confirm to Activate - ${this.driverDetails.name} `;
      const payload={
        driverId: this.driverDetails.token,
        id: index,
        date: moment.now(),
     reason: "Driver Activated",
        remarksBy: {
          name: localStorage.getItem("vendorName"),
        },
      }
    
      
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true;
        try {
          const response = await axios.patch("driver/activate", payload);

          if (response.status === 200) {
            this.$emit('trashChanged'); 
            this.loading = false;
            this.driverDetails.trash=0
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
          } else if (response.status === 206) {
            this.loading = false;
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
          }
        } catch (error) {
          this.loading = false;
          this.$toasted.show(error.response.data, {
            position: "bottom-center",
            duration: 4000
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./driverDetais";
</style>
