<template>
  <div class="filter-outer-blk">
    <div class="filter-search-blk">
      <v-text-field
          v-model="searchValue"
          :placeholder=placeHolder
          :label=placeHolder
          dense
          outlined
          hide-details
          @input="emitFilters"
          clearable
      >

      </v-text-field>
    </div>
    <div class="filter-blk" v-if="dateRange === false && filterData === 'toBeAssigned'">
      <v-menu
          v-model="dateModel"
          :nudge-right="40"
          :nudge-top="20"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-model="dateText"
              outlined
              label="Date"
              readonly
              dense
              v-on="on"
              hide-details
              :disabled="searchValue !== ''"
          >
          </v-text-field>

        </template>
        <v-date-picker v-model="date" @change="emitFilters" color="black"
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="filter-blk" v-if="dateRange === true && filterData === 'trips'">
      <v-menu
          v-model="dateRangeModal"
          :nudge-right="40"
          :nudge-top="20"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
      >
        <template v-slot:activator="{on}">

          <v-text-field
              v-model="dateRangeText"
              outlined
              label="Date Range"
              readonly
              dense
              v-on="on"
              hide-details
              :disabled="searchValue !== ''"

          >
          </v-text-field>
          <!--          :disabled="searchValue !== ''"-->
        </template>

        <v-date-picker v-model="dateRangeValue" range :max="today" color="black"></v-date-picker>

      </v-menu>
    </div>
    <div class="filter-blk" v-if="filterData === 'toBeAssigned' || filterData === 'trips'">
      <v-autocomplete
          v-model="branchState"
          placeholder="State"
          label="State"
          dense
          outlined
          hide-details="auto"
          :items="filters"
          item-text="name"
          return-object
          clearable
          :disabled="searchValue !== ''"
      >

      </v-autocomplete>
    </div>
<!--    <div class="filter-blk" v-else-if="filterData === 'Driver Management' || 'Vehicle Management'">-->
<!--      <v-autocomplete-->
<!--          v-model="state"-->
<!--          placeholder="State"-->
<!--          label="State"-->
<!--          dense-->
<!--          outlined-->
<!--          hide-details="auto"-->
<!--          :items="cityFilters"-->
<!--          item-text="name"-->
<!--          return-object-->
<!--          clearable-->
<!--      >-->
<!--      </v-autocomplete>-->
<!--    </div>-->
    <div class="filter-blk" v-if="filterData === 'toBeAssigned' || filterData === 'trips'">
      <v-autocomplete
          v-model="branch"
          placeholder="Branch"
          label="Branch"
          dense
          outlined
          hide-details="auto"
          :items="branchState.branch"
          item-text="name"
          return-object
          clearable
          :disabled="searchValue !== ''"
      >

      </v-autocomplete>
    </div>
<!--    <div class="filter-blk" v-else-if="filterData === 'Driver Management' || 'Vehicle Management'">-->
<!--      <v-autocomplete-->
<!--          v-model="city"-->
<!--          placeholder="City"-->
<!--          label="City"-->
<!--          dense-->
<!--          outlined-->
<!--          hide-details="auto"-->
<!--          :items="state.city"-->
<!--          item-text="name"-->
<!--          return-object-->
<!--          clearable-->
<!--      >-->

<!--      </v-autocomplete>-->
<!--    </div>-->
    <div class="reset-button-blk">
      <v-btn text color="#ff9500" @click="resetFilters"><i class="fas fa-redo"></i></v-btn>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import * as moment from 'moment'

export default {
  name: "Filters",
  props: {
    dateRange: Boolean,
    placeHolder: String,
    filterData: String,
  },
  data: () => {
    return {
      filters: [],
      cityFilters: [],
      searchValue: '',
      state: '',
      city: '',
      branchState: '',
      branch: '',

      dateModel: false,
      date: '',

      dateRangeModal: false,
      dateRangeValue: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      today: moment().add(2, 'days').format('YYYY-MM-DD'),
    }
  },
  watch: {
    searchValue() {
      if (this.searchValue === null) {
        this.searchValue = ''
      }
      this.emitFilters()
    },
    state() {
      if (this.state === null) {
        this.state = ''
        this.city = ''
      }
      this.emitFilters()
    },
    city() {
      if (this.city === null) {
        this.city = ''
      }
      this.emitFilters()
    },
    branchState() {
      if (this.branchState === null) {
        this.branchState = ''
        this.branch = ''
      }
      this.emitFilters()
    },
    branch() {
      if (this.branch === null) {
        this.branch = ''
      }
      this.emitFilters()
    },
    dateRangeValue() {
      if (this.dateRangeValue[0]) {
        if (this.dateRangeValue[1]) {
          if (this.dateRangeValue[0] > this.dateRangeValue[1]) {
            this.startDate = moment(this.dateRangeValue[1]).format('YYYY-MM-DD')
            this.endDate = moment(this.dateRangeValue[0]).format('YYYY-MM-DD')
          } else {
            this.startDate = moment(this.dateRangeValue[0]).format('YYYY-MM-DD')
            this.endDate = moment(this.dateRangeValue[1]).format('YYYY-MM-DD')
          }
          this.emitFilters();
          this.dateRangeModal = false
        }
        // else {
        //   this.startDate = moment(this.dateRangeValue[0]).format('YYYY-MM-DD')
        //   this.endDate = moment().format('YYYY-MM-DD')
        // }
      }

    }
  },
 async  mounted() {
    await this.getBranchFilter()
  },
  methods: {
    // async getFilters() {
    //   const response = await axios.get('/router/filterData')
    //   console.log(response)
    //   this.cityFilters = response.data
    // },
    async getBranchFilter() {
      const response = await axios.get('trip/branchesByState')
      console.log(response)
      this.filters = response.data

      console.log(this.filters)
    },
    resetFilters() {
      this.branchState = ''
      this.branch = ''
      this.searchValue = ''
      this.date = ''
      this.dateRangeValue = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      this.startDate = moment().format('YYYY-MM-DD')
      this.endDate = moment().format('YYYY-MM-DD')
      this.emitFilters();
    },
    emitFilters() {
      let payload = {
        start: this.filterData === 'trips' && this.searchValue === '' ? this.startDate : undefined,
        end: this.filterData === 'trips' && this.searchValue === '' ? this.endDate : undefined,
        date: this.filterData === 'toBeAssigned' && this.searchValue === '' ? this.date : undefined,
        state: this.branchState !== '' && this.searchValue === ''  ? this.branchState.id : undefined,
        searchValue: this.searchValue,
        branch: this.branch !== '' && this.searchValue === '' ? this.branch.id : undefined,
        // city: this.city !== '' ? this.city.id : undefined
      }
      this.$emit('get-filter', payload)
    }
  },
  computed: {
    dateText() {
      if(this.date !== '') {
        return moment(this.date).format('LL')
      } else {
        return ''
      }


    },
    dateRangeText() {
      let start = ''
      let end = ''
      if (this.dateRangeValue[0] > this.dateRangeValue[1]) {
        start = moment(this.dateRangeValue[1]).format('MMM DD')
        end = moment(this.dateRangeValue[0]).format('MMM DD')
      } else {
        start = moment(this.dateRangeValue[0]).format('MMM DD')
        end = moment(this.dateRangeValue[1]).format('MMM DD')
      }
      return `${start} ~ ${end}`

    },

  }
}
</script>

<style scoped lang="scss">
.filter-outer-blk {
  display: flex;
  align-items: center;
  width: 100%;

  .filter-search-blk {
    width: 20%;
  }

  .filter-blk {
    width: 15%;
    margin-left: 10px;
  }
}

::v-deep .v-text-field {
  font-size: 13px;
}

.v-list {
  padding: 3px 0;
}

</style>