<template>
  <div class="cab-profile-modal">
    <div class="cab-profile-tab--blk" v-if="vehicleId !== 0">
      <div class="cab-details-tab-modal">
        <v-card class="cab-profile-blk" elevation="2">
          <VehicleDetails :vehicleDetails="vehicleDetails" @edit-vehicle="editVehicleDetails" @getVehicleDetails="getVehicleDetails" ></VehicleDetails>
        </v-card>
      </div>
    </div>
    <div v-else class="no-vehicle-profile">
      <span>No Data Available</span>
    </div>
    <Loading v-if="loading"></Loading>
    
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import VehicleDetails from "@/components/vehicle-management/vehicle-details/VehicleDetails";
import Loading from "@/components/common/Loading";

export default {
  name: "VehicleProfile",
  components: { Loading, VehicleDetails },
  props: {
    vehicleId: Number,
    vehicleToken:String,
  },
  watch: {
    // vehicleId() {
    //   console.log(this.vehicleId,"@@@@")
    //   this.getVehicleDetails();
    // },
    vehicleToken(){
      this.getVehicleDetails()
    }
  },
  mounted() {

  },
  data: () => {
    return {
      vehicleDetails: {},
      loading: false,
    };
  },
  methods: {
    async getVehicleDetails() {
      if (this.vehicleId !== 0) {
        this.loading = true;
        console.log(this.vehicleToken,"----------vehicleID------------------")
        const response = await axios.post("vehicle/details", { vehicleId: this.vehicleToken });
        this.loading = false;
        this.vehicleDetails = response.data;
      }
    },
    editVehicleDetails(data) {
      this.$emit("edit-vehicle-data", data);
    },

  },
};
</script>

<style scoped lang="scss">
@import "./vehicleProfile";
</style>
