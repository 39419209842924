<template>
  <div class="vehicle-details-modal" v-if="vehicleDetails.details">
    <div class="vehicle-no-blk text-center">
      <span>{{ vehicleDetails.vehicleno }}</span>
    </div>
    <div class="manufacturer-blk text-center">
      <span>{{ vehicleDetails.details.manufacturer.name }}</span>
    </div>
    <div class="manufacturer-blk text-center">
      <span>{{ vehicleDetails.details.model.name }}</span>
    </div>
    <div class="manufacturer-blk text-center">
      <span>{{ vehicleDetails.category.name }}</span>
    </div>
    <div class="manufacturer-blk text-center">
      <span>{{ vehicleDetails.details.color.name }}</span>
    </div>
    <div class="manufacturer-blk text-center">
      <span>{{ vehicleDetails.details.year }}</span>
    </div>
    <div class="manufacturer-blk text-center" v-for="(data, index) in vehicleDetails.details.fuel" :key="index">
      <span>{{ data.name }}</span>
    </div>
    <div class="driver-blk text-center" v-if="vehicleDetails && vehicleDetails.drivers && vehicleDetails.drivers.length > 0">
      <div v-for="(driver, index1) in vehicleDetails.drivers" :key="driver.id">
        <span>{{ driver.name }}<b v-if="index1 !== vehicleDetails.drivers.length - 1">,</b></span>
      </div>
    </div>
    <div v-if="vehicleDetails && vehicleDetails.drivers && vehicleDetails.drivers.length === 0" class="text-center no-driver-blk">
      <span>No driver Assigned</span>
    </div>

    <div class="button-blk">
      <v-btn v-if="vehicleDetails.trash !== 1" color="#e9bd00" @click="deactivateVehicle">Deactivate Vehicle</v-btn>
      <v-btn v-else color="#636363">Deactivated</v-btn>
      <v-btn color="#eea004" @click="editVehicle" v-if="vehicleDetails.trash !== 1">Edit</v-btn>
    </div>
    <!-- <DeactivationModal v-if="deactivateModal" title="Deactivate Vehicle" :show="deactivateModal" :deactivateReason.sync="deactivateReason" :onConfirm="deactivateVehicle" @close-modal="closeModal">
    </DeactivationModal> -->
    <Confirm ref="confirm"></Confirm>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import DeactivationModal from "@/components/modal/DeactivationModal";
import Confirm from "@/components/common/Confirm";
import Loading from "@/components/common/Loading";


export default {
  name: "VehicleDetails",
  props: {
    vehicleDetails: Object,
  },
  components: { DeactivationModal, Confirm, Loading },
  data() {
    return {
      deactivateModal: false,
      deactivateReason: "",
      loading: false,
    };
  },
  mounted() {

  },
  watch: {
    vehicleDetails() {
      console.log(this.vehicleDetails, "_------------------");
    },
  },
  methods: {
    editVehicle() {
      this.$emit("edit-vehicle", this.vehicleDetails);
    },
    showDeactivateModal() {
      this.deactivateModal = true;
    },
    async deactivateVehicle() {
      // if (!reason) {
      //   this.$toasted.show("Please provide a reason for deactivation.", {
      //     position: "bottom-center",
      //     duration: 4000,
      //   });
      //   return;
      // }
      if (
        await this.$refs.confirm.open("Confirm Deactivate", "Are you sure, do you want to deactivate this vehicle?", {
          icon: "fas fa-exclamation",
          color: "red",
        })
      ) {
        const payload = {
          vehicleId: this.vehicleDetails.token,
          // reason: reason,
        };
        this.loading = true;
        try {
          const response = await axios.patch("/vehicle/deactivate", payload);
          if (response.status === 200) {
            this.loading = false;
            this.deactivateModal = false;
            this.$emit("getVehicleDetails");
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
          }else{
            this.loading = false;
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
          }
        } catch (error) {
          this.loading = false;
          this.$toasted.show("An error occurred. Please try again.", {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },

    closeModal() {
      this.deactivateModal = false;
      this.deactivateReason = "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "./vehicleDetails";
</style>
