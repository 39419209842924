<template>
  <div class="admin-list">
    <div class="admin-header">
      <span>Name</span>
      <span>Mobile</span>
      <span>Email</span>
      <span></span>
    </div>
    <div class="admin-list-container" v-for="(admin,index) in adminList" :key="index">
      <div class="admin-list-row">
        <span>{{ admin.name }}</span>
        <span>{{ admin.mobile }}</span>
        <span>{{ admin.email }}</span>
        <div class="button-column">
          <i class="fas fa-pen" @click="editAdmin(admin)"></i>
          <i class="fas fa-trash" @click="deleteAdmin(admin)"></i>
        </div>
      </div>
    </div>
    <loading v-if="loading"></loading>
    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import Confirm from "@/components/common/Confirm";
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";

export default {
  name: "adminList",
  components: {Loading, Confirm},
  props: {
    adminList: Array
  },
  data: () => {
    return {
      search: '',
      loading: false,
    }
  },
  methods: {
    editAdmin(admin) {
      this.$emit('on-click', admin)
    },
    async deleteAdmin(admin) {
      let confirmTitle = "Confirm Remove";
      let confirmText = "Please confirm to Remove Admin";
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true
        try {
          const response = await axios.post('admin/delete', {id: admin.id})
          this.loading = false
          if (response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 5000
            });
            this.$emit('on-remove')
          }
        } catch (error) {
          this.loading = false
        }
      }
    }
  },

}
</script>

<style scoped lang="scss">
.admin-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 60%;
  border-right: 2px solid #e9e9e9;
  height: calc(100vh - 150px);
  overflow-y: auto;

  .admin-header {
    display: flex;

    border-bottom: 1px solid #ecebeb;

    span {
      width: 25%;
      text-align: start;
      padding: 12px;
      font-weight: bold;
    }
  }

  .admin-list-container {
    .admin-list-row {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #ecebeb;

      span {
        width: 25%;
        text-align: start;
        padding: 12px;

      }

      .button-column {
        width: 25%;
        padding: 12px;
        display: flex;
        column-gap: 20px;
        justify-content: flex-end;
        align-items: center;
        .fas {
          cursor: pointer;
        }
      }
    }
  }
}
</style>