<template>
  <div v-if="driverDetails" class="driver-assign-outer-blk">
    <div class="driver-content-blk">
      <div class="driver-name-blk">
        <span class="driver-header-blk">Driver : </span>
        <span class="driver-blk">{{ driverDetails.name }}</span>
      </div>
      <div class="driver-alert-blk" v-if="driverDetails.tripList.length > 0"> 
        <span><i class="fas fa-exclamation-triangle"></i>This Driver is already on trip - </span>
        <span v-for="data in driverDetails.tripList" :key="data.id" class="margin-left">{{ data.id }}</span>
      </div>
    </div>

    <div class="vehicle-modal">
      <div class="vehicle-add-modal">
        <div class="text-field-blk">
          <v-text-field
              v-model="vehicleNo"
              placeholder="Vehicle No"
              label="Vehicle No"
              dense
              outlined
              hide-details
              @keydown.space.prevent
          >
          </v-text-field>
          <v-autocomplete
              v-model="model"
              placeholder="Model"
              label="Model"
              :items="modelList"
              return-object
              item-text="name"
              dense
              outlined
              clearable
              hide-details
          >

          </v-autocomplete>
        </div>
        <div class="add-assign-button-blk">
          <v-btn color="#eea004" :disabled="vehicleNo === '' || model === ''" @click="addAndAssign">Add & Assign</v-btn>
        </div>
      </div>
      <div class="vehicle-content-blk" v-for="(data,index) in driverDetails.vehicle" :key="index"
           :class="{'selected-vehicle-blk':data.id === vehicleData.id,'ongoing-vehicle-blk':data.tripId}"
           @click="selectVehicle(data)">
        <span class="vehicle-number-blk ">{{ data.vehicleNo }}</span>
        <span>{{ data.model.name }}</span>
        <span>{{ data.category }}</span>
        <span class="ongoing-trip-blk" v-if="data.tripId">Vehicle is already on trip - {{ data.tripId }}</span>
      </div>
    </div>
    <div class="assign-button-blk">
      <v-btn color="#bbb6b6" @click="closeDialog">Close</v-btn>
      <v-btn color="#eea004" @click="assignDriver(true)" :disabled="vehicleData === ''">Assign & Accept</v-btn>
    </div>

    <Loading v-if="loading"></Loading>
    <Confirm ref="confirm"></Confirm>

    <v-dialog
        width="500"
        v-model="dialogView"
    >
      <v-card class="card-modal-view">
        <span>Vehicle belongs to another vendor,please contact taSki to assign this vehicle</span>
        <div class="card-button-blk">
          <v-btn small color="#eea004" @click="closeDVehicleDialog">OK</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";

export default {
  name: "DriverAssign",
  components: {Confirm, Loading},
  props: {
    driverDetails: Object,
    bigId: Number,
    driverNumber: String,
    modelList: Array,
    currentState: Number,
    currentCity: Number
  },
  watch: {
    vehicleNo() {
      this.vehicleNo = this.vehicleNo.toUpperCase()
    },
    driverDetails(){
console.log(this.driverDetails,"_+---")
console.log(this.driverNumber,"===============")
    },
    model() {
      if (this.model === null) {
        this.model = ''
      }
    },
  },

  data: () => {
    return {
      vehicleData: '',

      vehicleNo: '',
      model: '',

      filterData: '',

      loading: false,

      dialogView: false,

    }
  },
  // async mounted() {
  //   await this.getFilters()
  // },
  methods: {
    selectVehicle(data) {
      this.vehicleData = data
      console.log(data)
    },
    // async getFilters() {
    //   const response = await axios.post('/router/modelList', {tripId: this.bigId})
    //   console.log(response)
    //   this.filterData = response.data
    // },
    async addAndAssign() {
      let confirmTitle = "Confirm Add & Assign";
      let confirmText = "Please confirm to Add and Assign the vehicle";
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true
        try {
          const payload = {
            driver: {
              name: this.driverDetails.name,
              number: this.driverNumber,
              city: this.currentCity,
              state: this.currentState
            },
            cab: {
              vehicleNo: this.vehicleNo,
              model: this.model,
              color: {id: 1, name: "White"},
              fuel: {id: 1, name: "Diesel"},
              year: 2018
            },
            tripId: this.bigId,
            driverConfirm: true,
          }
          const response = await axios.post('/router/tripAssign', payload)
          console.log(response)
          if (response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
            const emitDetails = {
              name: this.driverDetails.name,
              number: this.driverNumber,
              vehicleNo: this.vehicleNo
            }
            this.$emit('assign-driver', emitDetails)
          } else if (response.status === 206) {
            this.loading = false
            this.dialogView = true
            // this.$toasted.show(response.data, {
            //   position: "bottom-center",
            //   duration: 5000
            // });
          }
        } catch (error) {
          this.loading = false
          // this.$toasted.show(error.response.data, {
          //   position: "bottom-center",
          //   duration: 4000
          // });
        }
      }
    },
    async assignDriver(data) {
      let confirmTitle = "Confirm Assign";
      let confirmText = "Please confirm to Assign the driver";
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true
        try {
          const payload = {
            driver: {
              name: this.driverDetails.name,
              number: this.driverNumber,
              city: this.currentCity,
              state: this.currentState
            },
            cab: {
              vehicleNo: this.vehicleData.vehicleNo,
              model: this.vehicleData.model,
              color: this.vehicleData.color,
              fuel: this.vehicleData.fuel.length > 0 ? this.vehicleData.fuel[0] : {id: 1, name: "Diesel"},
              year: this.vehicleData.year,
            },
            tripId: this.bigId,
            driverConfirm: data,
          }
          const response = await axios.post('/router/tripAssign', payload)
          console.log(response)
          if (response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
            const emitDetails = {
              name: this.driverDetails.name,
              number: this.driverNumber,
              vehicleNo: this.vehicleData.vehicleNo
            }
            this.$emit('assign-driver', emitDetails)
          }
        } catch (error) {
          console.log(error)
          this.loading = false
          // this.$toasted.show(error.response.data, {
          //   position: "bottom-center",
          //   duration: 4000
          // });
        }
      }
    },

    closeDVehicleDialog() {
      this.dialogView = false
    },


    async acceptDriver() {
      await this.assignDriver()
    },
    closeDialog() {
      this.$emit('close-dialog')
    }
  }
}
</script>

<style scoped lang="scss">
@import "./driverAssign";
</style>