<template>
  <div class="main-inner-blk">
    <v-card class="trip-filter-blk" elevation="3">
      <Filters :dateRange="true" :filterData="'trips'" @get-filter="getFilters" :placeHolder="'Search ID/User'"></Filters>
    </v-card>

    <div class="trip-list-blk" :class="{ 'no-trip-blk': tripList.length === 0 }">
      <div v-if="tripList.length > 0">
        <div class="trip-list-content">
          <span class="ended-length-blk">Ended Trips: {{ endedTripCount }}</span>
          <span class="started-length-blk">Started Trips: {{ startedTripCount }}</span>
          <span class="trip-length-blk">Total Trips: {{ tripList.length }}</span>
        </div>
        <TripsView :tripList="tripList" ></TripsView>
      </div>
      <div class="no-trip-blk" v-if="tripList.length === 0">
        <span>No Trips to Show</span>
      </div>
    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>
<script>
import Filters from "@/components/common/filter/Filters";
import TripsView from "@/components/Trips/trips-view/TripsView";
import axios from "../../../axios/axios-global";
import Loading from "@/components/common/Loading";
import * as moment from "moment";

export default {
  name: "TripList",
  components: { Loading, TripsView, Filters },
  data: () => {
    return {
      tripList: [],

      loading: false,

      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),

      startedTripCount: 0,
      endedTripCount: 0,
    };
  },
  mounted() {
    this.getData({ start: this.startDate, end: this.endDate, search: "" });
  },
  methods: {
    async getData(data) {
      console.log(data, "------");
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      console.log(data);
      this.timeout = setTimeout(async () => {
        this.startedTripCount = 0;
        this.endedTripCount = 0;
        const response = await axios.post("trip/list", data);
        console.log(response.list,"!__!_!_!_!_!_!_!_!_!_!_!_!_!_!_!_!_!_!_!_!_!_!_!_");
        this.loading = false;
        this.tripList = response.data.list;
        if (this.tripList.length > 0) {
          this.tripList.map((data) => {
            console.log(data.status,"--------------")
            if (data.status.id === 7 || data.status.id === 8) {
              this.startedTripCount += 1;
            } else if (data.status.id === 10 || data.status.id === 9) {
              this.endedTripCount += 1;
            }
          });
        }
      }, 500);
    },
    getFilters(data) {
      console.log(data);
      this.getData({ start: data.start, end: data.end, search: data.searchValue, state: data.state, branch: data.branch });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./tripList";
</style>
