<template>

  <div class="main-trip-list-blk">
    <div class="trip-header-blk">
      <span class="id-blk">ID</span>
      <span class="date-blk">Date</span>
      <span class="user-blk">Employee</span>
      <span class="driver-blk">Driver & Car</span>
      <span class="time-blk">Time & KM</span>
      <!--      <span class="km-blk">KM</span>-->
      <span class="user-total-blk">User Total</span>
      <!--      <span class="toll-blk">Toll</span>-->
      <span class="reading-blk">Readings</span>
      <span class="trip-status-blk">Actions</span>
    </div>
    <div class="trip-list-blk" v-for="(data,index) in tripList" :key="index">
    
      <div class="id-blk content-blk">
        <span>{{ index+1 }}</span>
        <a @click="openDrawer(data.bigId)">{{ data.id }}</a>
        <!--        <span class="status-blk" :class="{'ended-status-blk':data.status.id === 10,'started-status-blk':data.status.id === 7}">{{data.status.status}}</span>-->
        <span class="started-status-blk status-blk" v-if="data.status.id === 7">Started</span>
      </div>
      <div class="date-blk content-blk">
        <span class="tooltip-blk"><strong>Start : </strong>{{ getDateTime(data.sTime) }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9"
              class="tooltip-blk"><strong>End : </strong>{{ getDateTime(data.eTime) }}</span>

      </div>
      <div class="user-blk content-blk">
        <span>{{ data.user.name }}</span>

        <span>{{ data.user.mobile }}</span>
        <span>{{ data.user.email }}</span>
        <span>{{ data.user.branch }}</span>
      </div>
      <div class="driver-blk content-blk">
        <span>{{ data.driver.name }}</span>
        <span>{{ data.driver.mobile }}</span>
        <span>{{ data.driver.vehicle }}</span>
        <strong v-if="data.tripType">{{data.tripType}}</strong>
      </div>
      <div class="time-blk content-blk">

        <span v-if="data.status.id === 10 || data.status.id === 9"> <strong>Travel Time :</strong> {{
            getTotalTime(data.userCalculation.travelTime)
          }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9"><strong>Travel KM :</strong> {{
            data.userCalculation.travelKM
          }}</span>
        <span v-if="data.status.id === 7"><strong>Approved KM :</strong> {{ data.userCalculation.approved }}</span>
        <!--        <span v-if="data.status.id === 10"><strong>Total Time :</strong> {{ getTotalTime(data.userCalculation.totalTime) }}</span>-->
      </div>
      <!--      <div class="km-blk content-blk">-->
      <!--        <span v-if="data.status.id === 10"><strong>Travel KM :</strong> {{ data.userCalculation.travelKM }}</span>-->
      <!--        &lt;!&ndash;        <span v-if="data.status.id === 10"> <strong>Total KM :</strong> {{ data.userCalculation.totalKM }}</span>&ndash;&gt;-->
      <!--        <span v-if="data.status.id === 7"><strong>Approved KM :</strong> {{ data.userCalculation.approved }}</span>-->
      <!--      </div>-->
      <div class="user-total-blk content-blk">
        <span v-if="data.status.id === 10 || data.status.id === 9">{{ data.userTotal.usermodel }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9">{{ data.userTotal.kmprice }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9">{{ data.userTotal.minprice }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9"><strong>Gst :</strong>{{ data.userTotal.gst }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9"><strong>Toll :</strong>{{
            data.userTotal.toll
          }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9"><strong>Sub Total :</strong>{{
            data.userTotal.subtotal
          }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9"><strong>Total :</strong>{{
            data.userTotal.usertotal
          }}</span>
      </div>

      <!--      <div class="toll-blk content-blk">-->
      <!--        <div class="toll-modal">-->
      <!--          <div class="flex-blk" v-for="(item,index) in data.toll" :key="index">-->
      <!--            <v-chip color="green" label small v-if="item.status === 1" title="Approved">-->
      <!--              <span>₹ {{ item.amount }}</span>-->
      <!--              <span @click="cancelToll(data,item)"><i class="fas fa-times"></i></span>-->
      <!--            </v-chip>-->
      <!--&lt;!&ndash;            <v-chip v-if="item.status === 0" color="orange" label small title="Waiting for approval">&ndash;&gt;-->
      <!--&lt;!&ndash;              <span>₹ {{ item.amount }}</span>&ndash;&gt;-->
      <!--&lt;!&ndash;              <span @click="cancelToll(data,item)"><i class="fas fa-times"></i></span>&ndash;&gt;-->
      <!--&lt;!&ndash;            </v-chip>&ndash;&gt;-->
      <!--&lt;!&ndash;            <v-chip label small color="red" v-if="item.status === 2" title="Rejected">&ndash;&gt;-->
      <!--&lt;!&ndash;              <span>₹ {{ item.amount }}</span>&ndash;&gt;-->
      <!--&lt;!&ndash;            </v-chip>&ndash;&gt;-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <v-btn x-small color="#facc15" @click="viewTollDialog(data)" v-if="data.verified !== 1">Add Toll</v-btn>-->
      <!--      </div>-->
      <div class="reading-blk content-blk">
        <span class="reading-modal" v-if="data.readings.start !== 0"><strong>Start Reading :</strong><span
            class="reading-content-modal"
            @click="imageView(data.readings,index)"><u>{{ data.readings.start }}</u></span></span>
        <span class="reading-modal" v-if="data.readings.end !== ''"><strong>End Reading : </strong><span
            class="reading-content-modal"
            @click="imageViewEnd(data.readings,index)"><u>{{ data.readings.end }}</u></span></span>
        <span class="reading-modal"
              v-if="data.readings.end !== ''"><strong>Difference : </strong>{{
            data.readings.end - data.readings.start
          }}</span>
        <!--        <label :for="data.id" class="upload-label-class" v-if="data.readings.end !== ''&& data.tripSheet === ''"><u>Upload Trip Sheet</u></label>-->
        <!--        <input type="file" accept="application/pdf,image/*" :id="data.id" class="input-blk" @change="selectTripSheet(data,$event)">-->
        <!--        <a :href="data.tripSheet" TARGET="_blank" v-if="data.tripSheet !== ''">View trip Sheet</a>-->
      </div>
      <div class="content-blk trip-status-blk">
        <span class="ended-status-blk status-blk" v-if="data.status.id === 10 || data.status.id === 9">Trip Ended</span>
        <!--        <v-btn color="#187bc9" @click="startTrip(data,index)" v-if="data.status.id === 4 || data.status.id === 5 || data.status.id === 6">Start Trip</v-btn>-->
        <v-btn v-if="(data.status.id === 7 || data.status.id === 8) && !enableETS" color="#ab0606"
               @click="startTrip(data,index)" small>End Trip
        </v-btn>
        <!--        <v-btn  @click="startTrip(data,index)" color="#187bc9">-->
        <!--          <span v-if="startedStatus === false">Start Trip</span>-->
        <!--&lt;!&ndash;          <i class="fas fa-car-side" v-if="startedStatus === true"></i>&ndash;&gt;-->

        <!--          <img src="../../../assets/images/car-svgrepo-com.svg"   v-if="startedStatus === true" class="fa-car-side">-->

        <!--        </v-btn>-->

      </div>
    </div>
    <div class="export-blk">

      <v-btn color="#3a854e" @click="exportReport">Export Report</v-btn>
    </div>
    <vue-easy-lightbox
        :visible="visible"
        :imgs="images"
        @hide="hideImage"
    >

    </vue-easy-lightbox>

    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        width="1200"
    >
      <TripDetails :tripId="tripId" v-if="drawer"></TripDetails>
    </v-navigation-drawer>
    <!--    <v-dialog-->
    <!--        v-model="tollDialog"-->
    <!--        width="650"-->
    <!--    >-->
    <!--      <TollAdd :tripId="bigId" @insert-toll="insertToll" v-if="tollDialog"></TollAdd>-->
    <!--    </v-dialog>-->

    <v-dialog
        v-model="startDialog"
        width="650"
        persistent
    >
      <StartEndDialog @close-dialog="closeDialog" :bigId="statusBigId" @start-end-trip="startEndTrip" :status="status"
                      v-if="startDialog"></StartEndDialog>
    </v-dialog>
    <Loading v-if="loading"></Loading>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import * as moment from 'moment'
import TripDetails from "@/components/Trips/trip-details/TripDetails";
import TollAdd from "@/components/Trips/TollAdd";
import VueExcel from "@/components/common/VueExcel";
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";
import StartEndDialog from "@/components/Trips/trips-view/StartEndDialog";

export default {
  name: "TripsView",
  components: {StartEndDialog, Confirm, Loading, TripDetails, TollAdd},
  props: {
    tripList: Array,
  },
  data: () => {
    return {
      visible: false,
      images: [],

      drawer: false,
      tripId: 0,
      openIndex: 0,

      loading: false,

      tollDialog: false,
      bigId: 0,

      startedStatus: false,
      startDialog: false,

      statusBigId: 0,
      status: '',
      enableETS: '',

      endedTripCount: 0,
      startedTripCount: 0,
    }
  },
  mounted() {
    this.enableETS = JSON.parse(localStorage.getItem('enableETS'))
  },
  watch:{
    tripList(){
      console.log(this.tripList,"=============================================")
    }
  },
  methods: {
    async selectTripSheet(data, event) {
      const files = event.target.files
      const fileImage = files[0]
      let formData = new FormData()
      formData.append('data', JSON.stringify({tripId: data.bigId}))
      formData.append('tripSheet', fileImage)
      this.loading = true
      const response = await axios.post('/router/tripSheetUpload', formData)
      if (response.status === 200) {
        this.$toasted.show(response.data.message, {
          position: "bottom-center",
          duration: 4000
        });
        this.loading = false
        const index = this.tripList.findIndex(item => item.bigId === data.bigId)
        if (index !== -1) {
          this.tripList[index].tripSheet = response.data.url
        }
      }
    },
    getDateTime(data) {
      return moment(data).format('DD-MM-YYYY LT')
    },
    getTotalTime(time) {
      let h = Math.floor(time / 60);
      let m = time % 60;
      h = h < 10 ? '' + h : h;
      m = m < 10 ? '' + m : m;
      return `${h} hrs ${m.toString().substring(0, 2)} mins`;
    },
    imageView(data) {
      this.visible = true
      if (data.end !== '') {
        this.images = [{src: data.startImage, title: data.start}, {src: data.endImage, title: data.end}]
      } else {
        this.images = [{src: data.startImage, title: data.start}]
      }

    },
    imageViewEnd(data) {
      this.visible = true
      this.images = [{src: data.endImage, title: data.end}, {src: data.startImage, title: data.start}]
    },
    hideImage() {
      this.visible = false
    },
    openDrawer(id) {
      console.log(id,"---------------")
      this.drawer = true
      this.tripId = id
    },
    viewTollDialog(data) {
      this.tollDialog = true
      this.bigId = data.bigId
    },
    insertToll(data) {
      this.tollDialog = false
      const index = this.tripList.findIndex(trip => trip.bigId === this.bigId)
      if (index !== -1) {
        this.tripList[index].toll.unshift(data)
        this.tripList[index].userTotal.toll += data.amount
      }
    },
    exportReport() {
      try {
        let tripData = []
        let header = {
          tripId: 'Trip Id',
          user: 'User',
          userMobile: 'User Number',
          tnlId: 'TNL ID',
          email: 'Email',
          branch: 'Branch',
          driver: 'Driver',
          driverNumber: 'Driver Number',
          vehicleNo: 'Vehicle Number',
          sDate: 'Start Date',
          eDate: 'End Date',
          travelTime: 'Travel Time',
          startReading: 'Start Reading',
          endReading: 'End Reading',
          travelKm: 'Travel KM',
          slabRate: 'Slab Rate',
          kmPrice: 'KM Price',
          minPrice: 'Min. Price',
          subTotal: 'Sub Total',
          gst: 'GST',
          toll: 'Toll',
          total: 'Total',

        }
        tripData.push(header)
        this.tripList.map(data => {
          let hour = Math.floor(data.userCalculation.travelTime / 60);
          let minute = data.userCalculation.travelTime % 60;
          hour = hour < 10 ? '' + hour : hour;
          minute = minute < 10 ? '' + minute : minute;
          let time = `${hour} hrs ${minute} mins`;
          let object = {
            tripId: data.id,
            user: data.user.name,
            userMobile: data.user.mobile,
            tnlId: data.user.empCode,
            email: data.user.email,
            branch: data.user.branch,
            driver: data.driver.name,
            driverNumber: data.driver.mobile,
            vehicleNo: data.driver.vehicle,
            sDate: moment(data.sTime).format('DD-MM-YYYY LT'),
            eDate: moment(data.eTime).format('DD-MM-YYYY LT'),
            travelTime: time,
            startReading: data.readings.start,
            endReading: data.readings.end,
            travelKm: data.userCalculation.travelKM,
            slabRate: data.userTotal.usermodel,
            kmPrice: data.userTotal.kmprice,
            minPrice: data.userTotal.minprice,
            gst: data.userTotal.gst,
            subTotal: data.userTotal.subtotal,
            toll: data.userTotal.toll,
            total: data.userTotal.usertotal,

          }
          tripData.push(object)
        })
        VueExcel.methods.downloadExcelSheet(tripData, 'Report', {skipHeader: true})
      } catch (e) {
        console.log(e)
      }
    },
    async cancelToll(data, toll) {
      let confirmTitle = "Confirm Cancel";
      let confirmText = "Please confirm to Cancel this Toll";
      let confirmIcon = "fas fa-exclamation";
      if (await this.$refs.confirm.open(confirmTitle, confirmText, {
        icon: confirmIcon,
        color: 'red',
      })) {
        this.loading = true
        try {
          const index = this.tripList.findIndex(trip => trip.id === data.id)
          if (index !== -1) {
            const tollIndex = this.tripList[index].toll.findIndex(item => item.id === toll.id)
            if (tollIndex !== -1) {
              this.tripList[index].toll.splice(tollIndex, 1)
              this.tripList[index].userTotal.toll -= toll.amount
              // console.log(data)
              // console.log(this.tripList[index].userTotal.toll)
            }
          }
          const response = await axios.post('/router/tollRemove', {
            tripId: data.bigId,
            toll: this.tripList[index].toll,
            tollId: toll.id
          })
          console.log(response)
          if (response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });

          } else if (response.status === 206) {
            this.loading = false
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000
            });
          }
        } catch (error) {
          this.loading = false
          this.$toasted.show(error, {
            position: "bottom-center",
            duration: 4000
          });
        }
      }
    },
    startTrip(data) {
      // this.startedStatus = true
      this.startDialog = true
      this.statusBigId = data.bigId
      // if(data.status.id === 4 || data.status.id === 5 || data.status.id === 6) {
      //   this.status = 'Start'
      // } else
      if (data.status.id === 7 || data.status.id === 8) {
        this.status = 'End'
      }
      // setTimeout(() => {
      //   this.startedStatus = false
      // },1600)
    },
    startEndTrip(data) {
      this.startDialog = false
      const index = this.tripList.findIndex(trip => trip.bigId === data.id)
      if (index !== -1) {
        if (data.status === 'Start') {
          this.tripList[index].status.id = 7
          this.tripList[index].readings.start = data.reading
          this.tripList[index].readings.startImage = data.image
        } else {
          this.tripList[index].status.id = 9
          this.tripList[index].userCalculation.travelTime = data.userCalculation.travelTime
          this.tripList[index].userCalculation.travelKM = data.userCalculation.travelKM
          this.tripList[index].userTotal.usermodel = data.userTotal.usermodel
          this.tripList[index].userTotal.kmprice = data.userTotal.kmprice
          this.tripList[index].userTotal.minprice = data.userTotal.minprice
          this.tripList[index].userTotal.gst = data.userTotal.gst
          this.tripList[index].userTotal.toll = data.userTotal.toll
          this.tripList[index].userTotal.subtotal = data.userTotal.subtotal
          this.tripList[index].userTotal.usertotal = data.userTotal.usertotal
          this.tripList[index].eTime = data.eTime
          this.tripList[index].readings.endImage = data.readings.endImage
          this.tripList[index].readings.end = data.readings.end
        }

      }
    },
    closeDialog() {
      this.startDialog = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "./tripView";
</style>